
/* Normal */
@font-face{
    font-family:"Proxima Nova";
    src:url("./ProximaNova/5596744/33ca2000-af7b-4444-97cd-6392ab2e24b2.woff2") format("woff2"),url("./ProximaNova/5596744/1c003cc6-0f8f-4c82-adde-a5a026b56b50.woff") format("woff");    
    font-weight: 400;
    font-style: normal;
}
/* Italic */
@font-face{
    font-family:"Proxima Nova";
    src:url("./ProximaNova/5596892/cf5ef906-e055-4b2c-8fc5-fcc4ab3ed9a2.woff2") format("woff2"),url("./ProximaNova/5596892/19f65509-8952-44f0-b007-4dc3c4ba8aed.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}
/* Bold */
@font-face{
    font-family:"Proxima Nova";
    src:url("./ProximaNova/5596929/3c35dc60-9fe3-480f-90ac-e453db0271e2.woff2") format("woff2"),url("./ProximaNova/5596929/2b2b7939-693b-44cd-873a-1aa065a906b8.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}
/* Bold Italic */
@font-face{
    font-family:"Proxima Nova";
    src:url("./ProximaNova/5596956/7eb49857-6450-4915-9c21-2759d4e531ca.woff2") format("woff2"),url("./ProximaNova/5596956/27bbc35f-00c4-4c26-8cd8-cb37149f6f89.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Klavika";
    src: url("./Klavika/KlavikaWebBasicRegular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal; }

@font-face {
    font-family: "Klavika";
    src: url("./Klavika/KlavikaWebBasicLight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal; }

@font-face {
    font-family: "Klavika";
    src: url("./Klavika/KlavikaWebBasicMedium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal; }

@font-face {
    font-family: "Source Sans Pro";
    src: url("./Source_Sans_Pro/SourceSansPro-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal; }

@font-face {
    font-family: "Source Sans Pro";
    src: url("./Source_Sans_Pro/SourceSansPro-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
    font-stretch: normal; }

@font-face {
    font-family: "Source Sans Pro";
    src: url("./Source_Sans_Pro/SourceSansPro-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal; }

@font-face {
    font-family: "Source Sans Pro";
    src: url("./Source_Sans_Pro/SourceSansPro-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
    font-stretch: normal; }

@font-face {
    font-family: "Source Code Pro";
    src: url("./Source_Code_Pro/SourceCodePro-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal; }

@font-face {
    font-family: "Source Code Pro";
    src: url("./Source_Code_Pro/SourceCodePro-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
    font-stretch: normal; }

@font-face {
    font-family: "Source Code Pro";
    src: url("./Source_Code_Pro/SourceCodePro-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal; }

@font-face {
    font-family: "Source Code Pro";
    src: url("./Source_Code_Pro/SourceCodePro-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
    font-stretch: normal; }